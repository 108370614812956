import React, {memo, useEffect, useState} from 'react';

type Props = {
    id: string,
}

const SolutionsCompScreen2 = ({screenKey, index2, index, screenId}: Props) => {

    const [isLoading, setLoading] = useState(true);

    setTimeout(() => {
        setLoading(false);
    }, 0);

    return (
        <div className={`solQuestionScreen solQuestionScreen${index}${screenId}`}>
            <img src={screenKey} alt="" className={`wow delay${index2}1 ${isLoading ? '' : 'animate'}`} />
        </div>   
    )
}

export default memo(SolutionsCompScreen2);
