import './styles.scss';
import React, {memo, useState} from 'react';

import {
    BigLogoImage,
    lang
} from "../../images";
import { Link } from 'gatsby';
import {AnchorLink} from "gatsby-plugin-anchor-links";
import ChooseLanguage from '../ChooseLanguage';
import MobileNavigation from '../MobileNavigation';

const HeaderSolutions = () => {

    const [modalStatus, setModalStatus] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    const showForm = (status) => {
        setModalStatus(status);
    }

    const toggleMenu = () => {
        console.log('click');
        setShowMenu(!showMenu);
    }

    return (
        <header>
            <div className="headerSol">
                <div className="headerSolLogo2">
                    <Link to="/">
                        <img src={BigLogoImage} alt=""/>
                    </Link>
                </div>
                <button onClick={() => toggleMenu()} className="mobileBurgerSol">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <div className="headerSolNav2">
                    <nav>
                        <ul>
                            <li><AnchorLink to="#tools">Tools</AnchorLink></li>
                            <li><AnchorLink to="#skills">Courses</AnchorLink></li>
                            <li><AnchorLink to="#aiAeer">Our AI Assistant</AnchorLink></li>
                            <li><AnchorLink to="#contacts">Contacts</AnchorLink></li>
                        </ul>
                    </nav>
                </div>
                <div className="headerSolLang" onClick={() => showForm(true)}>
                    <img src={lang} alt="" />
                </div>
            </div>

            <ChooseLanguage modalStatus={modalStatus} showForm={showForm} />

            {showMenu && (
                <MobileNavigation onClick={toggleMenu} relation="Corp" />
            )}
        </header>
    )
}

export default memo(HeaderSolutions);
