import './styles.scss';
import React, {memo, useEffect, useState} from 'react';
import parse from 'html-react-parser';
import SolutionsCompScreen1 from '../SolutionsComponents/screen1.tsx';
import SolutionsCompScreen2 from '../SolutionsComponents/screen2.tsx';
// import {fbq} from "@hutsoninc/gatsby-plugin-facebook-pixel";

import {Link, navigate} from "gatsby";

import {
    smallLogo,
    ArrowPrev,
    solFrame1,
    solScreen1,
    solFrame2,
    solScreen21,
    solScreen22,
    solFrame3,
    solScreen3,
    solFrame4,
    solScreen4,
} from "../../images";
import SolutionsTrial from '../SolutionsTrial';
import SolutionsFinal from '../SolutionsFinal';
import SolutionsTrialMain from '../SolutionsTrialMain';

type Props = {
    id: string,
    email: string
}

const SolutionsQuestion = ({id, email}: Props) => {

    const [isLoading, setLoading] = useState(true);
    const [pageName, setPageName] = useState('');
    const [activeQues, setActiveQues] = useState(1);
    const [isFinish, setIsFinish] = useState(false);
    const [pageViewStatus, setPageViewStatus] = useState(false);
    const [addCartStatus, setAddCartStatus] = useState(false);

    const [questions, setQuestions] = useState([
        {
            id: 1,
            pageName: 'education',
            type: 'screen',
            navigation: 'Education',
            title: 'Are you ready to make your soft skills <br /> strong with our interactive courses ?',
            frame: solFrame1,
            screen: solScreen1,
            btnName: 'I’m ready !',
            nextPage: 'targets',
        },
        {
            id: 2,
            pageName: 'targets',
            type: 'screen',
            navigation: 'Targets',
            title: 'Are you ready to make your goals more <br /> detailed and track their progress?',
            frame: solFrame2,
            screen: [
                solScreen21,
                solScreen22
            ],
            btnName: 'I’m ready !',
            nextPage: 'tasks',
        },
        {
            id: 3,
            pageName: 'tasks',
            type: 'screen',
            navigation: 'Tasks',
            title: 'Are you ready to set and achieve <br /> goals with AEER?',
            frame: solFrame3,
            screen: solScreen3,
            btnName: 'I’m ready !',
            nextPage: 'finances',
        },
        {
            id: 4,
            pageName: 'finances',
            type: 'screen',
            navigation: 'Finances',
            title: 'Are you ready to take control over <br /> your personal finances with our <br /> simple interface ?',
            frame: solFrame4,
            screen: solScreen4,
            btnName: 'I’m ready !',
            btnId: 'pageEducationReadyBtn',
            nextPage: 'trial',
        },
        {
            id: 5,
            pageName: 'trial',
            type: 'trial',
            navigation: 'Start new life',
        },
        {
            id: 6,
            pageName: 'finish',
            type: 'finish',
            navigation: 'Welcome',
        },
    ]);

    const getName = () => {
        questions.map((key) => {
            if(key.pageName === id){
                setActiveQues(key.id);
                setPageName(key.navigation);
            }
        });

        if(id === 'trial-main'){
            setActiveQues(5);
            setPageName('Start new life');
        }
    }

    const onSuccessPayment = () => {
        setIsFinish(true)
    }

    useEffect(() => {
        getName();

        if (!pageViewStatus && id === 'education') {
            // fbq("track", "ViewContent");
            console.log('ViewContent');
            setPageViewStatus(true);
        }

        if (!addCartStatus && id === 'finances') {
            // fbq("track", "AddToCart");
            console.log('AddToCart');
            setAddCartStatus(true);
        }
    });

    const renderScreens = (screen, index) => {
        if(typeof screen === 'object'){
            const returnEl = screen.map((screenKey, index2) => {
                const screenId = index2+1;
                return <SolutionsCompScreen2 key={`screen-${index2}`} screenKey={screenKey} index2={index2} screenId={screenId} index={index} />;
            });
            return returnEl;
        } else{
            return <SolutionsCompScreen1 index={index} screen={screen} />;
        }
    }

    return (
        <>
            <div className="solNavig">
                <div className="solBack" onClick={() => (id === 'trial' || id === 'trial-main') ? null : navigate(-1)}>
                    {
                        (id !== 'trial' && id !== 'trial-main') && (
                            <img src={ArrowPrev} alt="" />
                        )
                    }
                    <span>{pageName}</span>
                </div>
                <div className="solUser">
                    <span>{email}</span>
                    <img src={smallLogo} alt="" />
                </div>
            </div>
            <div className="solProgress">
                {
                    questions.map( (quesKey, index) => (
                        <div key={index} className={`solProgressSpace ${ (quesKey.id <= activeQues) ? 'active' : '' }`}></div>
                    ))
                }
            </div>

            {
                (id === 'trial-main') ? (
                    <SolutionsTrialMain onSuccess={onSuccessPayment} id={id} />
                ) : (
                    questions.map( (quesKey) => {
                        if(quesKey.pageName === id && quesKey.type === 'screen'){
                            return <div key={quesKey.id}>
                                <div className="solQuestion">
                                    <div className="solQuestionTitle">{parse(quesKey.title)}</div>
                                    <div className="solQuestionImage">
                                        <img src={quesKey.frame} alt="" className="solQuestionFrame" />
                                        {renderScreens(quesKey.screen, quesKey.id)}
                                    </div>
                                </div>

                                <div className="solQuestionNext">
                                    {/* {
                                        (quesKey.btnRemark) && (
                                            <div className="solQuestionInform">{quesKey.btnRemark}</div>
                                        )
                                    } */}
                                    <Link 
                                        className="solQuestionBtn" 
                                        to={`/solutions/${quesKey.nextPage}`}
                                        id={quesKey.btnId ? quesKey.btnId : ''}>{quesKey.btnName}</Link>
                                </div>
                            </div>
                        } else if((quesKey.pageName === id && quesKey.type === 'trial')){
                            return <SolutionsTrial key={quesKey.id} onSuccess={onSuccessPayment} id={id} />
                        } else if((quesKey.pageName === id && quesKey.type === 'finish')){
                            return <SolutionsFinal key={quesKey.id} id={id} email={email} />
                        }
                    })
                )
                
            }

            {/* <div className="solQuestion">
                <div className="solQuestionTitle">Начните 14-дневную пробную версию чтобы сформировать привычки</div>
                <p className="solQuestionText">1. Вы организуйте свои задачи, деталезируйте цели </p>
                <p className="solQuestionText">2. Вы возьмете под контроль управление бюджетом и взаиморасчетами</p>
                <p className="solQuestionText">3. Сформируете привычку проходить по 1-2 15-минутых курсов по развитию новыков</p>
                <p className="solQuestionText">Цена сегодня: Free (C вас не будет взыматься никакая оплата) </p>
                <p className="solQuestionText">У Вас будет 2 недели, чтобы увидеть, как AEER использует формирование привычек для создания долгосрочных результатов.</p>
                <p className="solQuestionText">
                    Цена после испытания <br />
                    1 - месячный план 19$ <br />
                    (4.99 a week)
                </p>
                <p className="solQuestionText">Счет будет выставлен (через 14 дней с момента когда юзер на этой странице), если он не будет отменен зарание.</p>
                <p className="solQuestionText">Вы сможете отменить подписку в любое время из личного кабинета, в 3 клика.</p>
            </div> */}


        </>
    )
}

export default memo(SolutionsQuestion);
