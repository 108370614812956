import '../styles/global.scss';
import '../styles/pages/solutions.scss';

import {HeadFC} from "gatsby";

import React, {useEffect, useState} from 'react'

import {
    sol1, sol2, sol3, sol4, sol5,
    BigLogoImage,
    LoadingGif
} from "../images";
import useAnalytics from "../hooks/useAnalytics";
import SolutionsMain from '../components/SolutionsMain';
import SolutionsQuestion from '../components/SolutionsQuestion';
import SolutionsTrialCorp from '../components/SolutionsTrialCorp';
import SolutionsFinal from '../components/SolutionsFinal';

type Props = {
    id: string,
    img: any,
    text: string
}

const SolutionsPage = ({id}: Props) => {
    // const {initAnalytics, saveEmail, saveSubType} = useAnalytics();
    const [email, setEmail] = useState('');
    const [isLoading, setLoading] = useState(true);
    const [focusStatus, setFocusStatus] = useState(false);
    const [isFinish, setIsFinish] = useState(false);

    const onPageLoad = () => {
        setLoading(false);
    }

    const storeEmail = () => {
        if(localStorage.getItem('email')){
            setEmail(localStorage.getItem('email'));
        }
    }

    useEffect(() => {
        storeEmail();

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    const onFocusEvent = () => {
        setFocusStatus(true);
        // window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    };

    const onBlurEvent = () => {
        setFocusStatus(false);
        // window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    };

    // useEffect(() => {
    //     initAnalytics()
    // }, []);

    const [advas, setAdvas] = useState([
        {
            id: 1,
            img: sol1,
            text: 'Develop your Soft Skills',
        },
        {
            id: 2,
            img: sol2,
            text: 'Manage your "To-Do List"',
        },
        {
            id: 3,
            img: sol3,
            text: 'Set and detail your goals',
        },
        {
            id: 4,
            img: sol4,
            text: 'Keep track of your money',
        },
        {
            id: 5,
            img: sol5,
            text: 'Record ideas and solutions',
        },
    ]);

    const onSuccessPayment = () => {
        setIsFinish(true)
    }

    return (
        <>
            {
                isLoading && (
                    <div className="loadWrap">
                        <div className="loadWrapLogo">
                            <img width={180} src={BigLogoImage} alt=""/>
                            <div className="loadWrapText">
                                <img width={16} src={LoadingGif} alt=""/>
                                Loading..
                            </div>
                        </div>
                    </div>
                )
            }
            <div className={`solutionsSection`}>
                <div className={`solutionsWrap ${(id === 'trial' || id === 'trial-main') ? 'pb30 trial' : ''} ${(id === 'finish') ? 'no-bg' : ''}`}>
                    {
                        (id === 'corp') && (
                            <SolutionsTrialCorp onSuccess={onSuccessPayment} id={id} />
                        )
                    }
                    {
                        (id === 'corp-final') && (
                            <SolutionsFinal id={id} email={email} />
                        )
                    }  
                    {
                        (id !== 'corp-final' && id !== 'corp') && (
                            <div className="solutionsInside">
                                {/* <SolutionsMain
                                    id={id}
                                    advas={advas} 
                                    onFocusEvent={onFocusEvent} 
                                    onBlurEvent={onBlurEvent} 
                                    setEmail={setEmail}
                                    focusStatus={focusStatus} /> */}
                                <SolutionsQuestion email={email} id={id} />
                            </div>
                        )
                    }      
                </div>
            </div>
        </>
    )
}

export default SolutionsPage;

export const Head: HeadFC = () => (
    <>
        <title>Solutions - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);
