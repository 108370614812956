import './styles.scss';
import React, {memo, useState} from 'react';

import {Link, navigate} from "gatsby";

import {
    Star,
    AppStoreV2,
    GPlayV2,
    masterCardV2,
    VisaV2,
    BigLogoRight
} from "../../images";

type Props = {
    id: string,
    email: string
}

const SolutionsFinal = ({id, email}: Props) => {

    return (
        <>
            
            <div className="solFinal">
                <div className="solFinalTitle">
                    <div className="solFinalTitleInside">
                        Thank you for your trust! <br /> Welcome to The AEER Platform
                    </div>
                </div>
                <div className="solFinalItems">
                    <div className="solFinalItem">
                        <div className="solFinalItemInside">
                            <img src={Star} alt="" />
                            <p>Password was sent to your email <br /> Use it to log in your personal account: {email}</p>
                        </div>
                    </div>
                    <div className="solFinalItem">
                        <div className="solFinalItemInside">
                            <img src={Star} alt="" />
                            <p>Download AEER App in App Store or Google Play Market </p>
                        </div>
                    </div>
                    <div className="solFinalItem">
                        <div className="solFinalItemInside">
                            <img src={Star} alt="" />
                            <p>Your free trial starts today and ends (Current date)</p>
                        </div>
                    </div>
                </div>
                <div className="solFinalSoc">
                    <Link 
                        // to="https://apps.apple.com/us/app/aeer-education-productivity/id1630377140?l=ru"
                        to="https://aeer.onelink.me/MldY/2qyyod8g"
                        id={`appStoreLast${id}`}
                        className="noEventsInside">
                        <img src={AppStoreV2} alt="" />
                    </Link>
                    <Link 
                        to="https://aeer.onelink.me/MldY/2qyyod8g"
                        id={`GplayStoreLast${id}`}
                        className="noEventsInside">
                        <img src={GPlayV2} alt="" />
                    </Link>
                </div>
                <div className="solTrialFooter fixed">
                    <div className="solTrialFooterSoc">
                        <img src={masterCardV2} alt="" />
                        <img src={VisaV2} alt="" />
                    </div>
                    <div className="solTrialFooterLogo">
                        <img src={BigLogoRight} alt="" />
                    </div>
                    <p className="solTrialFooterText">
                        © AEER PLATFORM INC <br />
                        8 THE GREEN STE A DOVER, DE 19901
                    </p>
                    <div className="solTrialFooterLinks">
                        <Link to="/terms">Terms of User</Link>
                        <Link to="/privacy">Privacy policy</Link>
                        <Link to="/cookie">Cookie policy</Link>
                        <Link to="/cancel">Unsubscribe</Link>
                    </div>
                </div>
            </div>
            
        </>     
    )
}

export default memo(SolutionsFinal);
