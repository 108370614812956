import './styles.scss';
import React, {memo} from 'react';
import {HomeOverviewComponent} from "../../types/ApiComponent";
import {AppleStoreImage, PlayMarketImage} from "../../images";
import {WITH_API_BASE} from "../../configs/appConfigs";

type Props = {
    data: HomeOverviewComponent
}

const HomeOverviewSolutions = ({data}: Props) => {
    return (
        <div id={'tools'}>
            <div className="homeOverviewSol">
                <div className="homeOverviewSolBox">
                    <div className="homeOverviewSolLeft">
                        <div className="homeOverviewSolTitle">
                            {data.Title}
                        </div>
                        {/* <div className="homeOverviewSolDesc" dangerouslySetInnerHTML={{__html: data?.Description || '' }}></div> */}
                        <div className="homeOverviewSolList">
                            <p>Develop your Soft Skills with the interactive courses</p>
                            <p>Take control over your personal finances with a simple interface</p>
                            <p>Manage your "To-Do List", set goals and achieve them</p>
                            <p>Record ideas and solutions to track your personal achievements history</p>
                        </div>
                        
                        {/* <div className="homeOverviewSolStores">
                            <div>
                                <a href={data.AppStore}>
                                    <img src={AppleStoreImage} width={207} alt=""/>
                                </a>
                                <a href={data.PlayMarket}>
                                    <img src={PlayMarketImage} width={207} alt=""/>
                                </a>
                            </div>
                        </div> */}
                    </div>
                    <div className="homeOverviewSolImage">
                        <img src={WITH_API_BASE(data.BgImage?.data?.attributes?.url || '')} width={684} height={511} alt=""/>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default memo(HomeOverviewSolutions);
