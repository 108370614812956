import './styles.scss';
import React, {memo, useEffect, useState, useRef, useMemo} from 'react';

import {Link, navigate} from "gatsby";
import {Elements} from "@stripe/react-stripe-js";
import PageService, {HomePageComponents} from "../../services/pageService";
import {ApiComponentType} from "../../types/ApiComponent";
import {loadStripe} from "@stripe/stripe-js";
import CardFormDark from "../CardFormDark";
import {StripeError} from "@stripe/stripe-js/types/stripe-js/stripe";
// import {fbq} from "@hutsoninc/gatsby-plugin-facebook-pixel";

import {
    graphTrial,
    checkCircle,
    seoAeer,
    masterCardV2,
    VisaV2,
    BigLogoRight,
    StripeImage2,
    LoadingGif
} from "../../images";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import useAnalytics from "../../hooks/useAnalytics";
import SolutionsAI from '../SolutionsAI';
import SolutionsList from '../SolutionsList';
import SolutionsScreenshots from '../SolutionsScreenshots';

type Props = {
    id: string,
    onSuccess: () => void
}

const SolutionsTrialMain = ({ id, onSuccess }: Props) => {
    const cardFormRef = useRef();
    const [isLoading, setLoading] = useState(false);
    const [isCardValid, setCardValid] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState<string>('0.0');
    const [email, setEmail] = useState(localStorage.getItem('email'));
    const {initAnalytics, saveEmail, saveSubType, saveTrialPrice} = useAnalytics();
    const [eventStatus, setEventStatus] = useState(false);

    const [data, setData] = useState<HomePageComponents>([]);

    useEffect(() => {
        initAnalytics();

        PageService.getCourseById(1).then((result) => {
            setData(result.data.data.attributes.Content)
        }).catch(() => {
        }).finally(() => {
            setLoading(false)
        });
    }, []);

    useEffect(() => {
        if (!eventStatus) {
            // fbq("track", "Lead");
            setEventStatus(true);
        }
    }, [eventStatus]);

    const onCardValid = (status: boolean) => {
        setCardValid(status);
    }

    const stripe = loadStripe('pk_live_51KoA95Gxzah9dg4QawTHGGHiG7NHrUucf3QYIWD69yXAuPARtiheAKsB5Ht3EUmTx5WgxPbrgcX52djjCRq6n6V8000ZFScU5d');

    const handleTokenCreate = (token: string, ev: any) => {
        makePayment(token, ev);
    }
    const makePayment = (cardToken: string, ev?: any) => {
        if (cardToken && email && email.length) {
            setLoading(true);
            axios.post(`https://aeer.app/api/v1/payment/stripe`, {
                cardToken,
                email,
                priceId: 'price_1Mcsg2Gxzah9dg4Q5vErOmRM',
                // priceId: 'price_1MhVWVGxzah9dg4Qs59bUhLJ'
            }).then((res) => {
                saveSubType(email)
                // fbq("track", "Purchase");
                if (ev) {
                    ev.complete('success');
                }
                navigate('/solutions/finish')
                if (onSuccess) {
                    onSuccess();
                }
            }).catch(() => {
                ev.complete('fail');
                toast('Payment error, please check your credit card data', {
                    autoClose: 5000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                })
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    const onPayPress = async () => {
        setLoading(true);
        const card = await (cardFormRef.current as any).onPayClick();
        setLoading(false);

        if (card.error) {
            console.log('Error', card.error);
            toast(card.error.message, {
                autoClose: 5000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
            return;
        }

        makePayment(card.setupIntent.payment_method);
    }

    const handleCardError = (e: StripeError) => {
        console.log('e', e)
    }

    const renderContent = useMemo(() => {
        return data.map((content, index) => {
            switch (content.__component) {
                case ApiComponentType.APP_SCREENSHOTS: {
                    return (
                        // <HomeScreenshots key={`item-${index}`} data={content}/>
                        <SolutionsScreenshots key={`item-${index}`} data={content} id={id} />
                    )
                }
            }
        })
    }, [data])

    return (
        <>

            <div className="solTrial">
                <div className="solTrialBox">
                    <div className="solTrialBoxInside">
                        <span>Improve yourself with a 7-day trial: <br /> get all the tools in one app <br /> to build habits</span>
                    </div>
                </div>
                <div className="solTrialGraph">
                    <img src={graphTrial} alt="" />
                    <div className="solTrialGraphInfo">
                        <div className="solTrialGraphItem">
                            <p>Soft-Skills</p>
                            <span className="blue"></span>
                        </div>
                        <div className="solTrialGraphItem">
                            <p>Finances</p>
                            <span className="green"></span>
                        </div>
                        <div className="solTrialGraphItem">
                            <p>Productivity</p>
                            <span className="yellow"></span>
                        </div>
                        <div className="solTrialGraphItem">
                            <p>Tasks/Targets</p>
                            <span className="orange"></span>
                        </div>
                        <div className="solTrialGraphItem">
                            <p>Analytics</p>
                            <span className="purple"></span>
                        </div>
                    </div>
                </div>

                {renderContent}

                <SolutionsAI />

                <SolutionsList />

                <div className="solTrialSeo">
                    <div className="solTrialSeoImage">
                        <img src={seoAeer} alt="" />
                    </div>
                    <div className="solTrialSeoInfo">
                        <p className="solTrialSeoTitle">Andrew Filatov 🇺🇦</p>
                        <p className="solTrialSeoSubtitle">Founder and CEO of AEER Platform Inc</p>
                        <p className="solTrialSeoText">I started my journey as an entrepreneur at the age of 15. <br /> The tools that my team and I introduced on the AEER platform have always been fundamental habits for me, thanks to which I manage to achieve new and visible results every day</p>
                    </div>
                </div>

                <div className="solTrialList">
                    <span className="solTrialListTitle">Terms of Use</span>
                    <p className="solTrialListSubtitle">
                    Make the best investment for yourself <br /> in one click
                    </p>

                    <div className="solTrialListItem">
                        <div className="solTrialListItemInside">
                            <img src={checkCircle} alt="" />
                            <span>7 Days Trial for free</span>
                        </div>
                    </div>
                    <div className="solTrialListItem">
                        <div className="solTrialListItemInside">
                            <img src={checkCircle} alt="" />
                            <span>I can cancel my subscription in any time</span>
                        </div>
                    </div>
                    {/* <div className="solTrialListItem">
                        <div className="solTrialListItemInside">
                            <img src={checkCircle} alt="" />
                            <span>This is an auto-renewing subscription</span>
                        </div>
                    </div> */}
                    <div className="solTrialListItem">
                        <div className="solTrialListItemInside">
                            <img src={checkCircle} alt="" />
                            <span>If I cancel during my 7-day trial, AEER will not charge me</span>
                        </div>
                    </div>
                    <div className="solTrialListItem">
                        <div className="solTrialListItemInside">
                            <img src={checkCircle} alt="" />
                            <span>If I like AEER and use it after the 7 days free trial, my payment method will be automatically charged 24$/month until I canceled</span>
                        </div>
                    </div>
                    {/* <div className="solTrialListItem">
                        <div className="solTrialListItemInside">
                            <img src={checkCircle} alt="" />
                            <span>If I like AEER and use it after the 7 days trial (41% off), my payment method will be automatically charged 47.90$/month until I canceled</span>
                        </div>
                    </div> */}
                    <div className="solTrialListItem">
                        <div className="solTrialListItemInside">
                            <img src={checkCircle} alt="" />
                            <span>I can cancel my subscription by sending an in-app support message or by filling out a form on aeer-platform.com</span>
                        </div>
                    </div>
                    <div className="solTrialListItem">
                        <div className="solTrialListItemInside">
                            <img src={checkCircle} alt="" />
                            <span>If I cancel before the end of the subscription period, AEER can provide a partial refund upon request.</span>
                        </div>
                    </div>
                    {/* <div className="solTrialListItem">
                        <div className="solTrialListItemInside">
                            <img src={checkCircle} alt="" />
                            <span>This subscription is subject to the AEER Terms of Use and Privacy Policy</span>
                        </div>
                    </div> */}
                </div>

                <div className="solTrialPrice">
                    <div className="solTrialPriceItem">
                        <p className="solTrialPriceLabel">Price today - $1 (pre-authorization payment, it is automatically reversed)</p>
                        {/* <p className="solTrialPriceLabel">Price today - 4,90$ (41% off)</p> */}
                        
                        <div className="solTrialPriceField">
                            <span>$1</span>
                            <span>USD</span>
                        </div>
                        <p className="solTrialPriceText">In 1 weeks, you will see how AEER uses habit formation to create long-term results.</p>
                    </div>
                    <div className="solTrialPriceItem">
                        <p className="solTrialPriceLabel">Price after trial - 24$/month</p>
                        <div className="solTrialPriceField">
                            <span>$24</span>
                            <span>USD</span>
                        </div>
                        <p className="solTrialPriceText star">The invoice will be issued 7 days after using the page (unless it is canceled in advance).</p>
                        <p className="solTrialPriceText star">You can cancel your subscription at any time in 3 clicks from your personal account.</p>
                    </div>
                </div>

                <div className="solTrialPayment">
                    <div className="solTrialPaymentTitle">Payment methods</div>
                    <div className="solTrialPaymentText">You won't be charged today, but you'll need to link your card to activate your 7-day trial period.</div>
                    {/* <div className="solTrialPaymentText">You need to link your card to activate your 7-day trial period. Price today - 4,99$ (41% off). You won’t be charged for monthly payment</div> */}
                    
                    <div className="paypage">
                        <div className="paypageWidget">
                            <Elements stripe={stripe}>
                                <CardFormDark
                                    trialPrice={Number.parseFloat(paymentMethod)}
                                    onGetCardToken={handleTokenCreate}
                                    onCardError={handleCardError}
                                    ref={cardFormRef}
                                    onValidationChange={onCardValid}/>
                            </Elements>
                        </div>
                        <button disabled={!isCardValid && isLoading} onClick={onPayPress} className="paypageSubmit" id={`pageTrial-${id}-ReadyBtn`}>
                            {(!isCardValid && isLoading) ? 'Wait..' : 'I’m ready to start!'}
                        </button>
                        <div className="paypageStripe dark">
                            <img src={StripeImage2} alt=""/>
                            <span>Your data is 100% secure. <br/> The payment is processed by Stripe</span>
                        </div>
                    </div>
                </div>

                <div className="solTrialFooter">
                    <div className="solTrialFooterSoc">
                        <img src={masterCardV2} alt="" />
                        <img src={VisaV2} alt="" />
                    </div>
                    <div className="solTrialFooterLogo">
                        <img src={BigLogoRight} alt="" />
                    </div>
                    <p className="solTrialFooterText">
                        © AEER PLATFORM INC <br />
                        8 THE GREEN STE A DOVER, DE 19901
                    </p>
                    <div className="solTrialFooterLinks">
                        <Link to="/terms" id={`terms-${id}-Link`}>Terms of User</Link>
                        <Link to="/privacy" id={`privacy-${id}-Link`}>Privacy policy</Link>
                        <Link to="/cookie" id={`cookie-${id}-Link`}>Cookie policy</Link>
                        <Link to="/cancel" id={`cancel-${id}-Link`}>Unsubscribe</Link>
                    </div>
                </div>
            </div>

            {isLoading && (
                <div className="loaderWrap">
                    <div className="loader">
                        <img width={44} src={LoadingGif} alt=""/>
                    </div>
                </div>
            )}

            <ToastContainer/>

        </>
    )
}

export default memo(SolutionsTrialMain);
