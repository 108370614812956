import './styles.scss';
import React, {memo, useMemo, useState} from 'react';

import {
    CrossIcon,
    langGB,
    langPT,
    langPL,
    langDE,
    langFR,
    langES,
    langUA,
    langRU,
} from "../../images";
import { Link } from 'gatsby';
import {AnchorLink} from "gatsby-plugin-anchor-links";
// import countryList from 'react-select-country-list';

const ChooseLanguage = ({modalStatus, showForm}) => {

    // const [value, setValue] = useState('')
    // const options = useMemo(() => countryList().getData().map((key) => {
    //     console.log(key);
    // }), []);

    const [activeLang, setActiveLang] = useState(0);
    const [langs, setLangs] = useState([
        {
            img: langGB,
            label: 'English'
        },
        {
            img: langPT,
            label: 'Portuguese'
        },
        {
            img: langPL,
            label: 'Polski'
        },
        {
            img: langDE,
            label: 'Deutsch'
        },
        {
            img: langFR,
            label: 'French'
        },
        {
            img: langES,
            label: 'Spanish'
        },
        {
            img: langUA,
            label: 'Ukrainian'
        },
        {
            img: langRU,
            label: 'Russian'
        }
    ]);

    // console.log(options);

    return (
        <div className={`langModalWrap ${modalStatus ? 'active' : ''}`}>
            <div className="langModalOverlay" onClick={() => showForm(false)}></div>
            <div className="langModal">
                <p className="langModalTitle">Choose a language</p>
                <button className="langModalClose" onClick={() => showForm(false)}>
                    <img src={CrossIcon} alt="" />
                </button>
                <div className="langModalBox">
                    {
                        langs.map((key, index) => (
                            <a href="" key={`item-${index}`} className={`langModalItem ${(activeLang === index) ? 'active' : ''}`}>
                                <img src={key.img} alt="" />
                                <span>{key.label}</span>
                            </a>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default memo(ChooseLanguage);
